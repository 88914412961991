<template>
  <div id="manageOSPExport" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Certification Validation Export</div>
      </div>
    </div>    
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-tabs color="white" dark slider-color="red">
          <v-tab>SafetyTraining New</v-tab>
          <v-tab>MedicalCheckup New</v-tab>
          <v-tab-item>
            <ejs-grid
              ref="gridReport1"
              id="GridReport1"
              :dataSource="reportSafety"
              :allowGrouping="true"
              :toolbar="toolbarOptions1"
              :allowExcelExport="true"
              :toolbarClick="toolbarClickReport1"
              :allowPaging="false"
              :allowSorting="true"
              :allowMultiSorting="true"
              :queryCellInfo="customiseCell"
            >
              <e-columns>
                <e-column field="StaffCode" headerText="Staff Code"></e-column>
                <e-column field="FullName" headerText="FullName"></e-column>
                <e-column field="Status" headerText="Status"></e-column>
                <e-column field="Department" headerText="Department"></e-column>
                <e-column field="JobPosition" headerText="JobPosition"></e-column>
                <e-column field="Company" headerText="Company"></e-column>
                <e-column field="DayLeft" headerText="DayLeft"></e-column>
                <e-column field="Expired" headerText="Expired" type="date" format="yMd"></e-column>
              </e-columns>
            </ejs-grid>
          </v-tab-item>

          <v-tab-item>
            <ejs-grid
              ref="gridReport2"
              id="GridReport2"
              :dataSource="reportMedical"
              :allowGrouping="true"
              :toolbar="toolbarOptions2"
              :allowExcelExport="true"
              :toolbarClick="toolbarClickReport2"
              :allowPaging="false"
              :allowSorting="true"
              :allowMultiSorting="true"
              :queryCellInfo="customiseCell"
            >
              <e-columns>
                <e-column field="StaffCode" headerText="Staff Code"></e-column>
                <e-column field="FullName" headerText="FullName"></e-column>
                <e-column field="Status" headerText="Status"></e-column>
                <e-column field="Department" headerText="Department"></e-column>
                <e-column field="JobPosition" headerText="JobPosition"></e-column>
                <e-column field="Company" headerText="Company"></e-column>
                <e-column field="DayLeft" headerText="DayLeft"></e-column>
                <e-column field="Expired" headerText="Expired" type="date" format="yMd"></e-column>
              </e-columns>
            </ejs-grid>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>  
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

  </div>
</template>
<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { PdfLineJoin } from "@syncfusion/ej2-pdf-export";
import { format, addDays, startOfDay, differenceInDays } from "date-fns";

import locale from "date-fns/locale/th";
//Grid จำเป็น
import {
  GridPlugin,
  Page,
  Sort,
  Group,
  Toolbar,
  ExcelExport
} from "@syncfusion/ej2-vue-grids";

export default {
  data: () => ({
    search: "",
    deleteItem: 0,
    dialogDelete: false,
    loading: false,
    UserItem: [],
    UserItem1: [],
    UserItem2: [],
    reportSafety: [],
    reportMedical: [],
    total: 0,
    total: 1,
    total: 2,
  
    toolbarOptions1: ["ExcelExport"],
    toolbarOptions2: ["ExcelExport"]
  }),
  computed: {},
  filters: {
    // date: createDateFilter("DD/MM/YYYY", { locale })
  },
  mounted: function() {
    this.renderUI();
  },
  provide: {
    grid: [Page, Sort, Group, Toolbar, ExcelExport]
  },

  methods: {
    Back() {
      this.$router.go(-1);
    },
    //ExportExcel
    toolbarClickReport1(args) {
      if (args.item.id === "GridReport1_excelexport") {
        // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.gridReport1.excelExport();
      }
    },
    //ExportExcel
    toolbarClickReport2(args) {
      if (args.item.id === "GridReport2_excelexport") {
        // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.gridReport2.excelExport();
      }
    },
    customiseCell(args) {
      if (args.column.field === "Status") {
        if (args.data["Status"] === "Warning") {
          args.cell.classList.add("txtorange");
        } else {
          args.cell.classList.add("txtred");
        }
      }
    },
    async renderUI() {
      try {
        //User
        const { user } = await feathersClientUOA.get("authentication");
        //User
        this.search = "";
        const q = {};
        q.r_id = 3;
        q.o_id = 2;
        var today = new Date();
        var result = addDays(new Date(), +90);
        var start = startOfDay(result);

        q.$or = [
          { u_safety_training: { $lt: start } },
          { u_medical_checkup: { $lt: start } }
        ];

        var res = await feathersClientUOA.service("viewuser").find({ query: q });
        this.total = res.total;
        this.UserItem = res.data;

        this.UserItem1 = this.UserItem.filter(
          x => x.SafetyTrainingDayLeft < 90
        );

        let rawdata = [];

        for (let i = 0; i < this.UserItem1.length; i++) {
          rawdata.push({
            StaffCode: this.UserItem1[i].u_staffcode,
            FullName: this.UserItem1[i].FullName,
            Status:
              this.UserItem1[i].SafetyTrainingDayLeft > 0
                ? "Warning"
                : "Expired",
            Department: this.UserItem1[i].u_department,
            JobPosition: this.UserItem1[i].u_jobposition,
            Company: this.UserItem1[i].c_name,
            DayLeft: this.UserItem1[i].SafetyTrainingDayLeft,
            Expired: this.UserItem1[i].SafetyTraining
          });
        }

        this.reportSafety = rawdata;

        this.UserItem2 = this.UserItem.filter(
          x => x.MedicalCheckupDayLeft < 90
        );
        let rawdata2 = [];

        for (let j = 0; j < this.UserItem2.length; j++) {
          rawdata2.push({
            StaffCode: this.UserItem2[j].u_staffcode,
            FullName: this.UserItem2[j].FullName,
            Status:
              this.UserItem2[j].MedicalCheckupDayLeft > 0
                ? "Warning"
                : "Expired",
            Department: this.UserItem2[j].u_department,
            JobPosition: this.UserItem2[j].u_jobposition,
            Company: this.UserItem2[j].c_name,
            DayLeft: this.UserItem2[j].MedicalCheckupDayLeft,
            Expired: this.UserItem2[j].MedicalCheckUp
          });
        }
        this.reportMedical = rawdata2;
      } catch (err) {
        alert("ไม่สามารถต่อ server ได้");
      }
    },

   
    wranningMedicalCheckup(MedicalCheckupDayLeft) {
      if (MedicalCheckupDayLeft != null) {
        if (MedicalCheckupDayLeft <= 90 && MedicalCheckupDayLeft >= 1) {
          var MedicalCheckupWranning =
            "MedicalCheckup Less than " + MedicalCheckupDayLeft + " days.";

          return MedicalCheckupWranning;
        } else if (MedicalCheckupDayLeft <= 0) {
          var MedicalCheckupExpired = "MedicalCheckup Expired";

          return MedicalCheckupExpired;
        }
      } else if (MedicalCheckupDayLeft == null) {
        var noMedicalCheckup = "No MedicalCheckup Date";

        return noMedicalCheckup;
      }
    },
    wranningSafetyTraining(SafetyTrainingDayLeft) {
      if (SafetyTrainingDayLeft != null) {
        if (SafetyTrainingDayLeft <= 90 && SafetyTrainingDayLeft >= 1) {
          var SafetyTrainingWranning =
            "SafetyTraining Less than " + SafetyTrainingDayLeft + " days.";

          return SafetyTrainingWranning;
        } else if (SafetyTrainingDayLeft <= 0) {
          var SafetyTrainingExpired = "SafetyTraining Expired";

          return SafetyTrainingExpired;
        }
      } else if (SafetyTrainingDayLeft == null) {
        var noSafetyTraining = "No SafetyTraining Date";

        return noSafetyTraining;
      }
    }
  }
};
</script>
